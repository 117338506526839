import './Banners.css';

type BannerProps = {
	side: string;
	count: number;
};

function Banners(props: BannerProps) {
	const banners = [];

	for (let i = 1; i <= props.count; i++) {
		banners.push(<img src={'https://loremflickr.com/800/450?' + props.side + '_' + i} alt={'Sponsor ' + i} />);
	}

	return <div className="banners">{banners}</div>;
}

export default Banners;
