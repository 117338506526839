import useTeamsStore from '../../Store/Teams';
import Team from '../Team/Team';
import './Scoreboard.css';

function Scoreboard() {
	const teamData = useTeamsStore((state) => state.teams);
	teamData.sort((a, b) => b.score - a.score);

	const teams = teamData.map((team, index) => (
		<Team id={team.id} key={team.id} name={team.name} players={team.players} rank={index + 1} score={team.score} />
	));

	return <div className="Scoreboard">{teams}</div>;
}

export default Scoreboard;
