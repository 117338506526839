import { useEffect } from 'react';

import './App.css';
import Banners from './Components/Banners/Banners';
import Logo from './Components/Logo/Logo';
import Scoreboard from './Components/Scoreboard/Scoreboard';
import useTeamsStore from './Store/Teams';

function App() {
	const addTeam = useTeamsStore((state) => state.addTeam);
	const increaseScore = useTeamsStore((state) => state.increaseScore);

	const detectKeyDown = (event: KeyboardEvent): void => increaseScore(event.key);

	useEffect(() => {
		addTeam('1', "Op z'n Grieks", ['Sander', 'Nora', 'Jumba']);
		addTeam('2', 'Monkey Town', ['Anna', 'Lotte', 'Henk', 'Dirk']);
		addTeam('3', 'FC De Klaplopers', ['Jasper', 'Sanne']);
		addTeam('4', 'Henkie Penkie', ['Thijs', 'Ruben']);
		addTeam('5', 'Napoleon Swag Force', ['Lars', 'Ilse', 'Jerome']);
		addTeam('6', '4_teh_m0therl4nd', ['Aleks', 'Olaf', 'Pjotr']);
		addTeam('7', 'Team 7', ['Naam 1']);
		addTeam('8', 'Team 8', ['Naam 1', 'Naam 2']);
		addTeam('9', 'Team 9', ['Naam 1', 'Naam 2', 'Naam 3']);
		addTeam('0', 'Team 10', ['Naam 1']);

		document.addEventListener('keydown', detectKeyDown, true);
	});

	return (
		<div className="App">
			<Banners side="left" count={5} />
			<main>
				<Logo />
				<Scoreboard />
			</main>
			<Banners side="right" count={5} />
		</div>
	);
}

export default App;
