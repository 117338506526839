import { useRef } from 'react';

import Team from '../../Types/Team';
import './Team.css';

function TeamComponent(team: Team) {
	let playerText = useRef<string | null>(null);

	if (playerText.current === null) {
		const playerNames = [...team.players];
		const lastPlayer = playerNames.pop();
		if (lastPlayer === undefined) {
			playerText.current = '?????';
		} else if (playerNames.length === 0) {
			playerText.current = lastPlayer;
		} else {
			playerText.current = [playerNames.join(', '), lastPlayer].join(' en ');
		}
	}

	let classes = 'Team ' + (team.rank === 1 && team.score > 10 ? 'on-fire ' : '');

	return (
		<div className={classes}>
			<img src="/images/flames.webp" alt="Flames" className="flames" />
			<div>
				<p className="rank">{team.rank}</p>
				<div className="text">
					<h1>{team.name}</h1>
					<p>{playerText.current}</p>
				</div>
				<p className="score">{team.score}</p>
			</div>
		</div>
	);
}

export default TeamComponent;
