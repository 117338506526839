import { create } from 'zustand';

import Team from '../Types/Team';

type TeamsStoreState = {
	teams: Team[];
	addTeam(id: string, name: string, players: string[]): void;
	increaseScore(id: string): void;
	resetScores(): void;
};

const useTeamsStore = create<TeamsStoreState>((set) => ({
	teams: [],

	addTeam: (id: string, name: string, players: string[]) =>
		set((state) => {
			if (state.teams.some((team) => team.id === id)) {
				return state;
			}
			return {
				teams: [...state.teams, { id: id, name: name, players: players, score: 0, rank: 1 }],
			};
		}),

	increaseScore: (id: string) =>
		set((state) => ({
			teams: state.teams.map((team) => (team.id === id ? { ...team, score: team.score + 1 } : team)),
		})),

	resetScores: () =>
		set((state) => ({
			teams: state.teams.map((team) => ({ ...team, score: 0 })).sort((a, b) => a.id.charCodeAt(0) - b.id.charCodeAt(0)),
		})),
}));

export default useTeamsStore;
