import useTeamsStore from '../../Store/Teams';
import './Logo.css';

function Logo() {
	const resetScore = useTeamsStore((state) => state.resetScores);

	return (
		<div className="Logo" onClick={resetScore}>
			<img src="/logo.png" title="Primitive Gym logo" alt="Logo" />
			<p>Green button event burpee edition 2025</p>
		</div>
	);
}

export default Logo;
